<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :store-module-name-product-category="STORE_MODULE_NAME_PRODUCT_CATEGORY"
      :store-module-name-questionnaire="STORE_MODULE_NAME_QUESTIONNAIRE"
      :is-sidebar-active.sync="isSidebarActive"
      :show.sync="show"
      :data="sidebarData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <label class="text-muted ml-2 mr-2">
                {{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}
              </label>
              <label for>{{ $t('entries') }}</label>

              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="select-size-sm"
                @input="pageSize"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <label>{{ $t('Add menu from system') }}</label>
              <b-row>
                <b-col cols="10">
                  <v-select
                    v-model="addMenuType"
                    size="sm"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OptionTypeMenu"
                    :reduce="OptionTypeMenu => OptionTypeMenu.value"
                    :clearable="true"
                    class="select-size-sm"
                  />
                </b-col>
                <b-col cols="2">
                  <b-button
                    variant="success"
                    size="sm"
                    @click="addMenu(addMenuType)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex mr-1"
                  size="sm"
                  @click="openMenuSort()"
                >
                  <span class="align-middle">{{ $t('Sort') }}</span>
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  @click="sidebarAddNew()"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
        >
          <template #cell(memberMenu)="data">
            <feather-icon
              v-if="data.item.memberMenu === true"
              color="green"
              icon="CheckCircleIcon"
              size="18"
            />
            <feather-icon
              v-if="data.item.memberMenu === false"
              color="red"
              icon="XCircleIcon"
              size="18"
            />
          </template>
          <template #cell(specialMenu)="data">
            <feather-icon
              v-if="data.item.specialMenu === true"
              color="green"
              icon="CheckCircleIcon"
              size="18"
            />
            <feather-icon
              v-if="data.item.specialMenu === false"
              color="red"
              icon="XCircleIcon"
              size="18"
            />
          </template>
          <!-- Column: Images -->
          <template #cell(image)="data">
            <b-row>
              <b-img
                v-if="data.item.image"
                :src="data.item.image"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
              <b-img
                v-else
                :src="require('@/assets/images/blank/no_image.png')"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
            </b-row>
          </template>
          <!-- Column: User -->
          <template #cell(name)="data">
            <p
              v-for="(item, index) in data.item.name"
              :key="index"
              class="align-middle ml-50"
            >
              {{ item.lang.toUpperCase() }}: {{ item.value }}
            </p>
          </template>
          <template #cell(positionMenu)="data">
            <p class="align-middle ml-50">
              {{
                data.item.positionMenu === 'BOTTOM_NAVBAR'
                  ? $t('Bottom Navbar')
                  : $t('Home Page')
              }}
            </p>
          </template>
          <!-- Column: User -->
          <template #cell(show)="data">
            <b-form-checkbox
              :checked="data.item.show"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('show', $event, data.item._id)"
            />
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="openSubMenu(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Sub Menu') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="sidebarEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BFormCheckbox,
  BDropdownItem,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/menu'
import storeModuleProductCategory from '@/store/services/eCommerce/productCategory'
import storeModuleQuestionnaire from '@/store/services/eCommerce/questionnaire'
import debounce from 'debounce'
import Sidebar from './DataSidebar.vue'

const STORE_MODULE_NAME = 'menu'
const STORE_MODULE_NAME_PRODUCT_CATEGORY = 'productCategory'
const STORE_MODULE_NAME_QUESTIONNAIRE = 'questionnaire'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    Sidebar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BFormCheckbox,
    BBadge,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      addMenuType: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'memberMenu', label: this.$t('Member Menu'), sortable: false },
        { key: 'specialMenu', label: this.$t('Special Menu'), sortable: false },
        {
          key: 'orderByMenu',
          label: this.$t('Order By Menu'),
          sortable: false,
        },
        { key: 'name', label: this.$t('Name'), sortable: false },
        {
          key: 'positionMenu',
          label: this.$t('positionMenu'),
          sortable: false,
        },
        // { key: 'image', label: this.$t('Image'), sortable: false },
        { key: 'show', label: this.$t('Show'), sortable: false },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    OptionTypeMenu() {
      return [
        { name: this.$t('HomePage'), value: 'homepage' },
        { name: this.$t('Product'), value: 'product' },
        { name: this.$t('Cart'), value: 'cart' },
        { name: this.$t('Notification'), value: 'notification' },
        { name: this.$t('Chat'), value: 'chat' },
        { name: this.$t('Me'), value: 'me' },
        { name: this.$t('Warehouse'), value: 'warehouse' },
        { name: this.$t('Feed'), value: 'feed' },
        { name: this.$t('Calendar Content'), value: 'calendarContent' },
        { name: this.$t('Admin Order'), value: 'adminOrder' },
        { name: this.$t('Admin Chat'), value: 'adminChat' },
        { name: this.$t('Admin Feed'), value: 'adminFeed' },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_PRODUCT_CATEGORY)) {
      store.registerModule(
        STORE_MODULE_NAME_PRODUCT_CATEGORY,
        storeModuleProductCategory,
      )
    }
    if (!store.hasModule(STORE_MODULE_NAME_QUESTIONNAIRE)) store.registerModule(STORE_MODULE_NAME_QUESTIONNAIRE, storeModuleQuestionnaire)

    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_PRODUCT_CATEGORY)) store.unregisterModule(STORE_MODULE_NAME_PRODUCT_CATEGORY)
    if (store.hasModule(STORE_MODULE_NAME_QUESTIONNAIRE)) store.unregisterModule(STORE_MODULE_NAME_QUESTIONNAIRE)
  },
  setup() {
    return {
      STORE_MODULE_NAME,
      STORE_MODULE_NAME_PRODUCT_CATEGORY,
      STORE_MODULE_NAME_QUESTIONNAIRE,
    }
  },
  methods: {
    openMenuSort() {
      this.$router.push({ path: '/e-commerce/menu/sort' })
    },
    switchChange(name, status, id) {
      const obj = {
        id,
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {})
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    openSubMenu(data) {
      this.show = true

      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to')} ${this.$t('Sub Menu')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          this.show = false

          if (value === true) {
            this.$router.push({ path: `/e-commerce/menu/${data._id}` })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    addMenu(name) {
      if (name != '' && name != null) {
        const tagMenu = {
          homepage: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f015',
              name: 'home',
            },
            iconSelected: {
              iconCode: 'f015',
              name: 'home',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'หน้าแรก',
              },
              {
                lang: 'en',
                value: 'Home Page',
              },
            ],
            tagMenu: 'HOME_PAGE',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: false,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          product: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f466',
              name: 'store',
            },
            iconSelected: {
              iconCode: 'f466',
              name: 'store',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'สินค้า',
              },
              {
                lang: 'en',
                value: 'Product',
              },
            ],
            tagMenu: 'PRODUCT',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: false,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          cart: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f07a',
              name: 'shoppingBag',
            },
            iconSelected: {
              iconCode: 'f07a',
              name: 'shoppingBag',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'ตะกร้า',
              },
              {
                lang: 'en',
                value: 'Cart',
              },
            ],
            tagMenu: 'CART',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: false,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          notification: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f0f3',
              name: 'bell',
            },
            iconSelected: {
              iconCode: 'f0f3',
              name: 'bell',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'การแจ้งเตือน',
              },
              {
                lang: 'en',
                value: 'Notification',
              },
            ],
            tagMenu: 'NOTIFICATION',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: false,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          chat: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'rocketChat',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'rocketChat',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'แชท',
              },
              {
                lang: 'en',
                value: 'Chat',
              },
            ],
            tagMenu: 'CHAT',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: true,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          me: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'user',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'user',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'ฉัน',
              },
              {
                lang: 'en',
                value: 'Me',
              },
            ],
            tagMenu: 'ME',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: false,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          warehouse: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'warehouse',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'warehouse',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'คลังสินค้า',
              },
              {
                lang: 'en',
                value: 'Warehouse',
              },
            ],
            tagMenu: 'WAREHOUSE',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: true,
            memberMenu: true,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          feed: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'feed',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'feed',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'ฟีด ',
              },
              {
                lang: 'en',
                value: 'Feed',
              },
            ],
            tagMenu: 'FEED',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: false,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          calendarContent: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'calendar',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'calendar',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'ปฏิทินกิจกรรม',
              },
              {
                lang: 'en',
                value: 'Calendar',
              },
            ],
            tagMenu: 'CALENDAR_CONTENT',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: false,
            memberMenu: false,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          adminOrder: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'list',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'list',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'คำสั่งซื้อ',
              },
              {
                lang: 'en',
                value: 'Order',
              },
            ],
            tagMenu: 'ADMIN_ORDER',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: true,
            memberMenu: true,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          adminChat: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'rocketChat',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'rocketChat',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'แชท',
              },
              {
                lang: 'en',
                value: 'Chat',
              },
            ],
            tagMenu: 'ADMIN_CHAT',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: true,
            memberMenu: true,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
          adminFeed: {
            image: null,
            iconFromSystem: null,
            icon: {
              iconCode: 'f406',
              name: 'feed',
            },
            iconSelected: {
              iconCode: 'f406',
              name: 'feed',
            },
            renderIcon: 'system',
            renderStyle: 0,
            name: [
              {
                lang: 'th',
                value: 'ฟีด ',
              },
              {
                lang: 'en',
                value: 'Feed',
              },
            ],
            tagMenu: 'ADMIN_FEED',
            positionMenu: 'BOTTOM_NAVBAR',
            action: 'none',
            target: 'IN_APP',
            urlLink: '',
            specialMenu: true,
            memberMenu: true,
            show: true, // สถานะในการแสดง
            typeMenu: 'PAGE',
          },
        }
        let obj = null
        obj = tagMenu[name]
        // const obj = {
        //   image: null,
        //   iconFromSystem: null,
        //   icon: {
        //     // eslint-disable-next-line no-nested-ternary
        //     iconCode: name === 'homepage' ? 'f015' : name === 'product' ? 'f466' : name === 'cart' ? 'f07a' : name === 'notification' ? 'f0f3' : name === 'chat' ? "f406" : name === 'me' ? 'f406' : '',
        //     // eslint-disable-next-line no-nested-ternary
        //     name: name === 'homepage' ? 'home' : name === 'product' ? 'store' : name === 'cart' ? 'shoppingBag' : name === 'notification' ? 'bell' : name === 'chat' ? "rocketChat" : name === 'me' ? 'user' : '',
        //   },
        //   iconSelected: {
        //     // eslint-disable-next-line no-nested-ternary
        //     iconCode: name === 'homepage' ? 'f015' : name === 'product' ? 'f466' : name === 'cart' ? 'f07a' : name === 'notification' ? 'f0f3' : name === 'chat' ? "f406" : name === 'me' ? 'f406' : '',
        //     // eslint-disable-next-line no-nested-ternary
        //     name: name === 'homepage' ? 'home' : name === 'product' ? 'store' : name === 'cart' ? 'shoppingBag' : name === 'notification' ? 'bell' : name === 'chat' ? "rocketChat" : name === 'me' ? 'user' : '',
        //   },
        //   renderIcon: 'system', // ชนิดการแสดงของ Icon
        //   renderStyle: 0, // ชนิดการแสดงของ Style
        //   name: [
        //     // eslint-disable-next-line no-nested-ternary
        //     { lang: 'th', value: name === 'homepage' ? 'หน้าแรก' : name === 'product' ? 'สินค้า' : name === 'cart' ? 'ตะกร้า' : name === 'notification' ? 'การแจ้งเตือน' : name === 'chat' ? 'แชท' : name === 'me' ? 'ฉัน' : '' },
        //     // eslint-disable-next-line no-nested-ternary
        //     { lang: 'en', value: name === 'homepage' ? 'Home Page' : name === 'product' ? 'Product' : name === 'cart' ? 'Cart' : name === 'notification' ? 'Notification' : name === 'chat' ? "Chat" : name === 'me' ? 'Me' : '' },
        //   ],
        //   // eslint-disable-next-line no-nested-ternary
        //   tagMenu: name === 'homepage' ? 'HOME_PAGE' : name === 'product' ? 'PRODUCT' : name === 'cart' ? 'CART' : name === 'notification' ? 'NOTIFICATION' : name === 'chat' ? "CHAT" : name === 'me' ? 'ME' : '',
        //   positionMenu: 'BOTTOM_NAVBAR',
        //   action: 'none',
        //   target: 'IN_APP',
        //   urlLink: '',
        //   memberMenu: name === 'chat' ? true : false,
        //   show: true, // สถานะในการแสดง
        //   typeMenu: 'PAGE',
        // }
        if (obj !== null && obj !== undefined) {
          store
            .dispatch(`${STORE_MODULE_NAME}/add`, obj)
            .then(result => {
              this.show = false

              // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            })
            .catch(error => {
              this.show = false
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
              // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
            })
        }
      }
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            const obj = { id }
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, obj)
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
